<template>
  <div class="modal team">
    <div class="box one mHeader flexB">
      <h1>{{ $t("user-home-user-version-notice-info-title")}}</h1>
      <a @click="close"><i class="fas fa-times"></i></a>
    </div>
    <div class="box one mBody">
      <div class="tableWrap">
        <table class="appInfo">
          <colgroup>
            <col style="width: 15%" />
            <col style="width: 35%" />
            <col style="width: 15%" />
            <col style="width: 35%" />
          </colgroup>
          <tr>
            <th>{{$t("user-home-user-version-app-type")}}</th>
            <td>
              <p>
                {{
                  appType == 'ViewApp'?
                      $t("user-home-user-version-app-type-viewer")
                      : appType == 'ControllerApp'
                          ? $t("user-home-user-version-app-type-controller")
                          : "-"
                }}
              </p>
            </td>
            <th>{{$t("user-home-user-version-app-version")}}</th>
            <td style="width: 100%;">
              <p style="line-height: 35px; max-width: 182px; overflow:hidden; text-overflow:ellipsis; white-space:nowrap; display:inline-block;">
                {{version}}
              </p>
            </td>
          </tr>
          <tr>
            <th>{{$t("user-home-user-version-app-is-open")}}</th>
            <td colspan="3">
              {{
                isOpen ?
                    $t("user-home-user-version-app-answer-yes")
                    : !isOpen
                        ? $t("user-home-user-version-app-answer-no")
                        : "-"
              }}
            </td>
          </tr>
          <tr>
            <th>{{$t("user-home-user-system-notice-contents")}}</th>
            <td colspan="3">
              <p style="width: 100%; height: 200px; margin: 5px 0; resize: none; white-space: pre-line; overflow-y: auto;">
                {{releaseNote}}
              </p>
            </td>
          </tr>
        </table>
      </div>
      <div v-if="fileName">
        <template>
          <div class="container">
            <!-- 업로드된 리스트 -->
            <div class="file-upload-list">
              <div class="file_upload_form" @click="downloadFile(fileName)">
                <span>{{fileName}}</span>
                <img src="@/assets/images/ico_download.png" alt="icon" style="width: 1.5rem; height: 1.5rem; margin-left: 12px;"/>
              </div>
            </div>
          </div>
        </template>

      </div>
        <div class="buttonWrap flexB">
          <button v-if="isAdmin" class="point large redBtn" style="background-color: #fff;border:1px solid #ddd;color:#969696;" @click="deleteVersionApp()">{{ $t("btn-delete") }}</button>
          <button v-else class="large" @click="close" style="margin: auto;">{{ $t("btn-confirm-close") }}</button>
          <button v-if="isAdmin" class="point large" @click="patch()">{{ $t("btn-data-patch") }}</button>
        </div>
    </div>
  </div>
</template>

<script>
import {mapState} from "vuex";
import {deleteVersionApp, fetchVersionAppInfo, getVersionAppFile} from "@/api/version";

export default {
  computed: {
    ...mapState({
      isAdmin: state => state.store.isAdmin,
    }),
  },
  props : {
    memo : {
      type : String,
      default : null,
      file: "",
      fileSrc:"",
    },
    versionAppLogId:{
      type: String,
    }
  },
  data() {
    return {
      appType: null,
      version: null,
      isOpen: null,
      releaseNote: null,
      fileName: null,
    };
  },
  async mounted(){
    this.getVersionAppInfo();
  },
  methods: {
    close() {
      this.$emit("close");
    },
    patch() {
      this.$emit("patch", this.versionAppLogId);
    },
    onFileChange(e){
      this.file = e.target.files[0];

      let reader = new FileReader();
      reader.readAsDataURL(this.file);
      reader.onload = (e) => {
        this.fileSrc = e.target.result;
      };
    },
    getVersionAppInfo(){
      fetchVersionAppInfo(this.versionAppLogId).then((res)=>{
        this.appType = res.data.data.appType;
        this.version = res.data.data.version;
        this.isOpen = res.data.data.isOpen;
        this.releaseNote = res.data.data.releaseNote;
        this.fileName = res.data.data.fileName;
      });
    },
    downloadFile(fileName){
      let xhr = new XMLHttpRequest();
      xhr.onreadystatechange = function(){
        if (this.readyState == 4 && this.status == 200){
          let filename = fileName;
          let disposition = xhr.getResponseHeader('Content-Disposition');
          if (disposition && disposition.indexOf('attachment') !== -1) {
            let filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
            let matches = filenameRegex.exec(disposition);
            if (matches != null && matches[1]) filename = matches[1].replace(/['"]/g, '');
          }
          //this.response is what you're looking for
          let a = document.createElement("a");
          let url = URL.createObjectURL(this.response)
          a.href = url;
          a.download = filename;
          document.body.appendChild(a);
          a.click();
          window.URL.revokeObjectURL(url);
        }
      }
      xhr.open('GET', getVersionAppFile(this.versionAppLogId));
      xhr.responseType = 'blob'; // !!필수!!
      xhr.setRequestHeader("Access-Control-Request-Headers", "Content-Description")
      xhr.send(); // 파라미터 설정하는 부분이며 formData 설정 부분은 생략
    },
    deleteVersionApp(){
      if(confirm(this.$t("site-view-summary-system-notice-ask-delete-notice"))){
        deleteVersionApp(this.versionAppLogId).then((res)=>{
          if (res.data.result === 0){
            alert(this.$t("alert-message-delete"));
            this.close();
            this.$router.go();
          }
        });
      }
    }
  }
};
</script>
<style>
.file_upload_form{
  width: fit-content;
  height: 30px;
  line-height: 25px;
  margin-top: 10px;
  border: 1px solid #dddddd;
  border-radius: 15px;
  padding: 0 16px;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
}
.file_upload_form>img{
  display: flex;
  margin: auto;
}
</style>

<style lang="scss">
.tableWrap{
  table.appInfo{
    th{
      background: #ebebeb;
      text-align: center;
      border-bottom: 1px solid #ebebeb;
    }
    td{
      background : white;
    }&:hover{
       background: transparent;
     }

    th:nth-child(1),
    th:nth-child(3),
    th:nth-child(5) {
      width: 110px;

    }
  }
}
</style>
