import axios from "axios";
import { baseUrl, baseApiVersion, headers } from "../config/env";

export function fetchVersionAppLogList(params) {
    return axios.get(baseUrl + baseApiVersion + `/version/log/list`, { params, headers });
}

export function createVersionApp(data){
    return axios.post(baseUrl + baseApiVersion + `/version/log`, data,{ headers });
}

export function createVersionAppFileUpload(data){
    headers["Content-Type"] = "multipart/form-data";
    return axios.post(baseUrl + baseApiVersion + `/version/log/files`, data, {
        headers,
    });
}

export function fetchVersionAppInfo(versionAppLogId){
    return axios.get(baseUrl + baseApiVersion + `/version/log/${versionAppLogId}`, { headers });
}

export function getVersionAppFile(versionAppLogId){
    return baseUrl + baseApiVersion + `/version/log/download/${versionAppLogId}`;
}

export function deleteVersionAppAttachedFile(versionAppLogId){
    return axios.delete(baseUrl + baseApiVersion + `/version/log/file/${versionAppLogId}`, {
        headers,
    });
}

export function updateVersionAppInfo(versionAppLogId, data){
    return axios.patch(baseUrl + baseApiVersion + `/version/log/${versionAppLogId}`, data, {
        headers,
    });
}

export function deleteVersionApp(versionAppLogId){
    return axios.delete(baseUrl + baseApiVersion + `/version/log/${versionAppLogId}`, {
        headers,
    });
}